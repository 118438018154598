import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import { dateToUnix } from '../util/date';

// Filter to only events in the correct locale,
// or english events that lack a locale.
const filterEventsByLocale = (events: TimelineEvent[], locale: string) => events.filter((event) =>
  (event.locale === locale) ||
  (event.locale === 'en' && event.localizations.find((localization) => localization.locale === locale) === undefined)
);

// Filter to only events in category
const filterEventsByCategory = (events: TimelineEvent[], category: string) => events.filter((event) =>
	(event.category === category)
);

export const queryEvents = (category: string, locale: string) => {
	const data = useStaticQuery(graphql`
	  query MyQuery {
	    allStrapiEvent {
	      nodes {
	        id
	        title
	        text
	        timestamp
	        region
					category
	        locale
	        localizations {
	          locale
	        }
	        source {
	          id
	          title
	          url
	        }
	        person {
	          id
	          name
	          party
	          title
	          photo {
	            localFile {
	              childImageSharp {
	                gatsbyImageData(
	                  width: 200
	                  placeholder: BLURRED
	                  formats: [JPG]
	                )
	              }
	            }
	          }
	        }
	        counterpoint {
	          country
	          id
	          title
	          url
	          timestamp
	        }
		      clarification {
		        title
		      }
	      }
	    }
	  }
	`);

	const events = filterEventsByCategory(filterEventsByLocale(data.allStrapiEvent.nodes, locale), category);
	events.sort((a, b) => dateToUnix(a.timestamp) - dateToUnix(b.timestamp));

	return events;
}
