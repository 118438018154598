import React from "react";
import { Link } from "gatsby";
import { TimelineEvent } from '../types';
import ReactMarkdown from 'react-markdown'
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";
import { toSlug } from '../util/slug';

type TimelineProps = {
  events: TimelineEvent[],
};

// GraphQL is retarded
const toArray = (list: any) => Array.isArray(list) ? list : [list];

// Wat
const flagToEmoji = (code: string) => {
  if (typeof code !== 'string') return '';
  const codePoints = code
    .toUpperCase()
    .split('')
    .map(char =>  127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
}

export const Timeline: React.FC<TimelineProps> = ({ events }) => {
  const intl = useIntl();
  return (
    <div className="timeline">
      <div className="stripe stripe-left bg-red"></div>
      <div className="stripe stripe-mid bg-yellow"></div>
      <div className="stripe stripe-right bg-black"></div>

      <div className="regions">
        <div className="region-dot region-wallonia" title={intl.formatMessage({ id: "Wallonia" })} />
        <div className="region-dot region-flanders" title={intl.formatMessage({ id: "Flanders" })} />
        <div className="region-dot region-brussels" title={intl.formatMessage({ id: "Brussels" })} />
      </div>

      <div className="events">
        {events.map((event) => (
          <Event key={event.id} event={event} />
        ))}
      </div>
    </div>
  );
}

export const Event: React.FC<EventProps> = ({event}) => {
  const intl = useIntl();

  const {
    id, title, text, timestamp, region,
    person, source, counterpoint, clarification,
  } = event;

  const classNames = ['event'];
  classNames.push(`region-${region}`);

  let photo = null;
  let name = null;
  let sub = null;

  if (person) {
    name = (
      <div className="person-name">{person.name}</div>
    );

    if (person.party || person.title) {
      const suffix = person.party ? <div className="person-party"> ({person.party})</div> : null;
      sub = (
        <div className="person-title">{person.title}{suffix}</div>
      );
    }

    if (person.photo) {
      const image = getImage(person.photo.localFile);
      photo = (
        <div className="person-photo">
          <GatsbyImage image={image} alt={person.name} />
        </div>
      );
    }
  }

	let extra = null;
	if (clarification) {
		const slug = toSlug(clarification.title);
		extra = (
			<div className="event-clarification"><dl><div>
        <dt>{intl.formatMessage({ "id": "Clarification" })}</dt>
        <dd><Link to={`/clarification/${slug}/`}>{clarification.title}</Link></dd>
			</div></dl></div>
		);
	}

  let sources = null;
  if (source && source.length) {
    const list = toArray(source);
    sources = <div className="event-sources"><dl>{
      list.map(({title, url}, i) => (
        <div key={i.toString()}>
          <dt>{intl.formatMessage({ "id": "Source" })}</dt>
          <dd><a href={url} target="_blank">{title}</a></dd>
        </div>
      ))
    }</dl></div>;
  }

  let counter = null;
  if (counterpoint && counterpoint.length) {
    const list = toArray(counterpoint);
    counter = <div className="event-counter"><dl>{
      list.map(({country, title, url, timestamp}, i) => {
        const prefix = country ? <span className="event-counter-country">{flagToEmoji(country)}</span> : null;
        let link = <a href={url} target="_blank">{title}</a>;
        if (timestamp) {
          link = <>{link} <time dateTime={timestamp}>{timestamp}</time></>;
        }
        if (prefix) return (
          <div key={i.toString()}>
            <dt>{prefix}</dt>
            <dd>{link}</dd>
          </div>
        );
        return <dd key={i.toString()}>{link}</dd>;
      })
    }</dl></div>;
  }

  return (
    <div className={classNames.join(' ')}>
      <div className="event-dot" />
      <div className="event-triangle-shadow" />
      <div className="event-triangle" />
      <div className="event-content">
        {photo}
        {name}
        {sub}
        <h2>{title}</h2>
        <time dateTime={timestamp}>{timestamp}</time>
        <div className="event-text">
          <ReactMarkdown>{text}</ReactMarkdown>
        </div>
				{extra}
        {counter}
        {sources}
      </div>
    </div>
  );
}